import React, { useContext } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import TPPaper from '../tpPaper/TPPaper';
import MintModal from '../mintModal/MintModal';
import { StoreContext } from '../../mobx/RootStore';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		heroContainer: {
			marginTop: '-20px',
			background: 'linear-gradient(1.94deg, #81D5F2 -6.55%, #00ADEE 52.45%)',
			marginBottom: '-20px',
			paddingBottom: theme.spacing(15),
			paddingTop: theme.spacing(20),
		},
		heroText: {
			paddingTop: theme.spacing(8),
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
		},
		mainHeroText: {
			fontFamily: 'Fredoka One',
			lineHeight: '1.2',
			fontSize: '2rem',
			color: theme.palette.text.primary,
			[theme.breakpoints.down('lg')]: {
				fontSize: '2rem',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
				paddingTop: 0,
			},
		},
		subHeroText: {
			fontFamily: 'Fredoka One',
			lineHeight: '1.2',
			color: theme.palette.text.secondary,
			fontSize: '4rem',
			[theme.breakpoints.down('lg')]: {
				fontSize: '3rem',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
			},
		},
		topRoll: {
			minWidth: '100%',
			marginBottom: '-4px',
			padding: 0,
		},
		paper: {
			height: 'auto',
		},
	}),
);

export const Hero = observer(() => {
	const classes = useStyles();
	const store = useContext(StoreContext);
	const {
		uiState: { desktopView },
	} = store;
	return (
		<>
			<Grid className={classes.heroContainer} container direction="row" justifyContent="center">
				<Grid item lg={4} xs={8}>
					<Grid className={classes.heroText} container direction="column">
					<Typography className={classes.mainHeroText}>Minting will end</Typography>
					<Typography className={classes.mainHeroText}>1 week after the 1000th</Typography>
					<Typography className={classes.mainHeroText}>Rainbow Roll is minted!</Typography>
					{desktopView ? <MintModal /> : null}
					</Grid>
				</Grid>
				<Grid className={classes.paper} item lg={3} xs={12}>
					<TPPaper />
				</Grid>
				{!desktopView ? <MintModal /> : null}
			</Grid>
		</>
	);
});

export default Hero;
