import { RouterStore } from 'mobx-router';
import { createContext } from 'react';
import { WalletStore } from './WalletStore';
import UiStore from './UiStore';

export class RootStore {
	public router: RouterStore<RootStore>;
	public wallet: WalletStore;
	public uiState: UiStore;

	constructor() {
		this.router = new RouterStore<RootStore>(this);
		this.wallet = new WalletStore(this);
		this.uiState = new UiStore(this);
	}
}

const store = new RootStore();

export const StoreContext = createContext({} as RootStore);
export const StoreProvider = StoreContext.Provider;

export default store;
