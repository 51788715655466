import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { StandardTypography } from '../common/typography';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			paddingTop: theme.spacing(24),
			paddingBottom: theme.spacing(24),
			backgroundColor: 'white',
			textAlign: 'center',
		},
		header: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '3rem',
			},
			[theme.breakpoints.down('md')]: {
				paddingBottom: theme.spacing(8),
				fontSize: '2rem',
			},
		},
		subHeader: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(12),
		},
		imageContainer: {
			height: '288px',
			[theme.breakpoints.down('md')]: {
				height: '180px',
			},
		},
		smallImage: {
			maxWidth: '100%',
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				width: '160px',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		largeImage: {
			paddingBottom: theme.spacing(4),
			[theme.breakpoints.down('md')]: {
				paddingTop: theme.spacing(12),
				paddingBottom: theme.spacing(12),
				width: '160px',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		charitySubtitle: {
			marginBottom: theme.spacing(2),
			fontSize: '1.25rem',
			fontWeight: 'bolder',
			[theme.breakpoints.down('md')]: {
				marginBottom: theme.spacing(1),
			},
		},
	}),
);

export const Charities = observer(() => {
	const classes = useStyles();
	return (
		<Grid className={classes.mainContainer} container direction="column" alignItems="center">
			<Grid item xs={8}>
				<Typography className={classes.header} variant="h3" color="textPrimary">
					The biggest social flex in crypto…
				</Typography>
				<Typography className={classes.subHeader} variant="h5" color="textPrimary">
					Signal your commitment to funding public goods in Web 3, while wiping away medical debt for families
					in need!
				</Typography>
			</Grid>
			<Grid container direction="row" justifyContent="space-around">
				<Grid item md={2} xs={8}>
					<Grid container direction="column">
						<Grid container direction="column" justifyContent="center" className={classes.imageContainer}>
							<img
								alt="Gitcoin"
								onClick={() => window.open('https://gitcoin.co/')}
								className={classes.smallImage}
								src="assets/icons/gitcoin.svg"
							/>
						</Grid>
						<StandardTypography className={classes.charitySubtitle} color="textPrimary">
							Wipe away obstacles to getting Web 3 public goods projects live.
						</StandardTypography>
						<StandardTypography color="textPrimary">
							The Gitcoin community builds and funds digital public goods. 16.5% of the funds raised will
							be allocated towards funding Gitcoin projects.
						</StandardTypography>
					</Grid>
				</Grid>
				<Grid item md={2} xs={8}>
					<Grid container direction="column">
						<Grid container direction="column" justifyContent="center" className={classes.imageContainer}>
							<img
								alt="Giveth"
								onClick={() => window.open('https://giveth.io/')}
								className={classes.smallImage}
								src="assets/icons/giveth-full-logo.svg"
							/>
						</Grid>
						<StandardTypography className={classes.charitySubtitle} color="textPrimary">
							Wipe away roadblocks to funding social good projects.
						</StandardTypography>
						<StandardTypography color="textPrimary">
							A community dedicated to altruism and rewarding the creation of positive change, Giveth will
							receive an allocation of 16.5% of the total charitable funds raised.
						</StandardTypography>
					</Grid>
				</Grid>
				<Grid item md={2} xs={8}>
					<Grid container direction="column">
						<Grid container direction="column" justifyContent="center" className={classes.imageContainer}>
							<img
								alt="RIP Medical Debt"
								onClick={() => window.open('https://ripmedicaldebt.org/')}
								className={classes.smallImage}
								src="assets/icons/RIPMedicalDebt_01.svg"
							/>
						</Grid>
						<StandardTypography className={classes.charitySubtitle} color="textPrimary">
							Wipe away medical debt for families in need.
						</StandardTypography>
						<StandardTypography color="textPrimary">
							Rather than funding opaque charities or research institutes, we aim to wipe away medical
							debt from the source, by providing 20% of funds directly to RIP Medical Debt.
						</StandardTypography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
});

export default Charities;
