import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { MobxRouter } from 'mobx-router';
import { RootStore, StoreProvider } from './mobx/RootStore';
import { mainTheme } from './config/themes';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
	},
}));

interface AppProps {
	store: RootStore;
}

const App = (props: AppProps) => {
	const classes = useStyles();
	const store = props.store;

	return (
		<StoreProvider value={store}>
			<ThemeProvider theme={mainTheme}>
				<div className={classes.root}>
					<main className={classes.content}>
						<MobxRouter store={store} />
					</main>
				</div>
			</ThemeProvider>
		</StoreProvider>
	);
};

export default App;
