import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { StandardButton } from '../common/buttons';
import { DISCORD_LINK, TELEGRAM_LINK, TWITTER_LINK, OPENSEA_LINK, CONTRACT_LINK, TOKEN_LINK } from '../../config/constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			paddingTop: theme.spacing(24),
			paddingBottom: theme.spacing(24),
			backgroundColor: 'white',
		},
		socialButtonContainer: {
			textAlign: 'center',
		},
		socialButton: {
			borderWidth: '4px',
			marginTop: theme.spacing(6),
			width: '75%',
			[theme.breakpoints.down('md')]: {
				margin: 0,
				width: '100px',
				fontSize: '.75rem',
			},
		},
		leftButton: {
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(6),
				textAlign: 'center',
			},
		},
		centerButton: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(2),
				textAlign: 'center',
			},
		},
		rightButton: {
			textAlign: 'left',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(2),
				textAlign: 'center',
			},
		},
		headerText: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '3rem',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
			},
		},
		center: {
			textAlign: 'center',
		},
		rainbowPoo: {
			marginTop: 'auto',
			paddingBottom: 'auto',
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		buttonContainer: {
			[theme.breakpoints.down('md')]: {
				justifyContent: 'space-evenly',
			},
		},
	}),
);

export const Social = observer(() => {
	const classes = useStyles();
	return (
		<Grid className={classes.mainContainer} container direction="row" justifyContent="center">
			<Grid className={classes.rainbowPoo} item xs={1}>
				<img alt="rainbow poo" src="assets/images/rainbow-poo.svg" />
			</Grid>
			<Grid item xs={8} md={6}>
				<Typography className={`${classes.center} ${classes.headerText}`} variant="h3" color="textPrimary">
					BRINGING THE FRESHNESS BACK TO YOUR NFT COLLECTION
				</Typography>
				<Grid className={classes.buttonContainer} container direction="row" justifyContent="center">
					<Grid className={`${classes.socialButtonContainer} ${classes.leftButton}`} item lg={4} xs={8}>
						<StandardButton
							variant="outlined"
							className={classes.socialButton}
							onClick={() => {
								window.open(DISCORD_LINK);
							}}
						>
							DISCORD
						</StandardButton>
						<StandardButton
							variant="outlined"
							className={classes.socialButton}
							onClick={() => {
								window.open(OPENSEA_LINK);
							}}
						>
							OPENSEA
						</StandardButton>
					</Grid>
					<Grid className={`${classes.socialButtonContainer} ${classes.centerButton}`} item lg={4} xs={8}>
						<StandardButton
							variant="outlined"
							className={`${classes.socialButton}`}
							onClick={() => {
								window.open(TELEGRAM_LINK);
							}}
						>
							TELEGRAM
						</StandardButton>
						<StandardButton
							variant="outlined"
							className={`${classes.socialButton}`}
							onClick={() => {
								window.open(TOKEN_LINK);
							}}
						>
							TOKEN
						</StandardButton>
					</Grid>
					<Grid className={`${classes.socialButtonContainer} ${classes.rightButton}`} item lg={4} xs={8}>
						<StandardButton
							variant="outlined"
							className={`${classes.socialButton}`}
							onClick={() => {
								window.open(TWITTER_LINK);
							}}
						>
							TWITTER
						</StandardButton>
						<StandardButton
							variant="outlined"
							className={`${classes.socialButton}`}
							onClick={() => {
								window.open(CONTRACT_LINK);
							}}
						>
							CONTRACT
						</StandardButton>
					</Grid>
				</Grid>
			</Grid>
			<Grid className={classes.rainbowPoo} item xs={1}>
				<img alt="rainbow poo" src="assets/images/rainbow-poo.svg" />
			</Grid>
		</Grid>
	);
});

export default Social;
