import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DISCORD_LINK, RAINBOW_GRADIENT, TELEGRAM_LINK, TWITTER_LINK } from '../../config/constants';
import { StandardTypography } from '../common/typography';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		topContainer: {
			paddingTop: theme.spacing(24),
			paddingBottom: theme.spacing(4),
			width: '75%',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		bottomContainer: {
			paddingBottom: theme.spacing(12),
			marginTop: '-10px',
			width: '75%',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		rainbowBg: {
			marginTop: '-50px',
			background: RAINBOW_GRADIENT,
		},
		mainText: {
			fontFamily: 'OpenSauceOneBlack',
			[theme.breakpoints.down('md')]: {
				fontSize: '2rem',
			},
		},
		mainSubText: {
			fontFamily: 'OpenSauceOneBlack',
		},
		secondaryText: {
			textAlign: 'right',
			fontFamily: 'OpenSauceOneBlack',
			[theme.breakpoints.down('md')]: {
				fontSize: '.75rem',
			},
		},
		socialImage: {
			paddingLeft: theme.spacing(2),
			height: '30px',
			cursor: 'pointer',
		},
	}),
);

const Footer = () => {
	const classes = useStyles();
	return (
		<div className={classes.rainbowBg}>
			<Grid
				className={classes.topContainer}
				container
				direction="row"
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Grid item xs={6}>
					<StandardTypography className={classes.mainText} variant="h2" color="textSecondary">
						NFTP
					</StandardTypography>
				</Grid>
				<Grid item xs={6}>
					<Grid container direction="row" justifyContent="flex-end">
						<img
							alt="Discord"
							className={classes.socialImage}
							src="assets/images/discord.png"
							onClick={() => {
								window.open(DISCORD_LINK);
							}}
						/>
						<img
							alt="Twitter"
							className={classes.socialImage}
							src="assets/images/twitter.png"
							onClick={() => {
								window.open(TWITTER_LINK);
							}}
						/>
						<img
							alt="Telegram"
							className={classes.socialImage}
							src="assets/images/telegram.png"
							onClick={() => {
								window.open(TELEGRAM_LINK);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				className={classes.bottomContainer}
				container
				direction="row"
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Grid item xs={6}>
					<StandardTypography className={classes.mainSubText} color="textSecondary">
						Non-Fungible Toilet Paper
					</StandardTypography>
				</Grid>
				<Grid item xs={6}>
					<StandardTypography className={classes.secondaryText} color="textSecondary">
						NFTP 2021
					</StandardTypography>
				</Grid>
			</Grid>
		</div>
	);
};

export default Footer;
