import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Toolbar } from '@material-ui/core';
import { Header } from '../Header';
import { observer } from 'mobx-react-lite';
import WalletWidget from './WalletWidget';
export const navHeight = 4;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			marginTop: '2.5rem',
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			zIndex: 2,
			boxShadow: 'none',
			height: `${navHeight}rem`,
			background: 'none',
		},
		navBarWrapper: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		navBarContainer: {
			height: '100%',
		},
		center: {
			textAlign: 'center',
		},
		right: {
			marginLeft: 'auto',
			textAlign: 'right',
		},
	}),
);

export const Navbar = observer(() => {
	const classes = useStyles();

	return (
		<AppBar position="absolute" className={classes.appBar}>
			<Toolbar className={classes.navBarWrapper}>
				<Grid className={classes.navBarContainer} container direction="row">
					<Grid className={classes.navBarContainer} item xs={4}>
						<Header />
					</Grid>
					<Grid className={`${classes.navBarContainer} ${classes.right}`} item xs={4}>
						<WalletWidget />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
});

export default Navbar;
