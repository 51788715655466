import { extendObservable, action } from 'mobx';
import { RootStore } from './RootStore';
import { JsonRpcProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';
import { Contract, ethers } from 'ethers';
import MINT_ABI from '../config/ABI/mint_contract.json';
import { INFURA_ID, MINT_ADDRESS, MINT_PRICE } from '../config/constants';
import WalletConnectProvider from '@walletconnect/web3-provider';
import BigNumber from 'bignumber.js';

export class WalletStore {
	private store: RootStore;
	public provider: Web3Provider | JsonRpcProvider | null;
	public signer: ethers.Wallet | JsonRpcSigner | null;
	public modalProvider: any | null;
	public address: string | null;
	private mintContract: Contract | null;
	public mintedAmount: number | null;
	public currentBlock: number | null;
	public presalePaused: boolean;
	public salePaused: boolean;
	public isWhitelisted: boolean;
	private web3Modal: Web3Modal;

	constructor(store: RootStore) {
		this.store = store;
		this.provider = null;
		this.signer = null;
		this.modalProvider = null;
		this.address = null;
        this.mintContract = null;
		this.mintedAmount = null;
		this.currentBlock = null;
		this.presalePaused = true;
		this.salePaused = true;
		this.isWhitelisted = false;
		this.web3Modal = new Web3Modal({
			network: 'mainnet',
			cacheProvider: true,
			providerOptions: {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						infuraId: INFURA_ID
					}
				}
			}
		});

		extendObservable(this, {
			provider: this.provider,
			signer: this.signer,
			modalProvider: this.modalProvider,
			address: this.address,
			mintContract: this.mintContract,
            mintedAmount: this.mintedAmount,
            currentBlock: this.currentBlock,
            presalePaused: this.presalePaused,
            salePaused: this.salePaused,
            isWhitelisted: this.isWhitelisted,
		});
	}

	setProvider = action(
		async (provider: Web3Provider | JsonRpcProvider): Promise<void> => {
			this.provider = provider;
			this.signer = provider.getSigner();
			this.address = await this.signer?.getAddress();
		}
	);

	setModalProvider = action((provider: any) => {
		this.modalProvider = provider;
	});

	connect = async () => {
		try {
			this.web3Modal.clearCachedProvider();
			const modalProvider = await this.web3Modal.connect();
			await this.setProvider(new Web3Provider(modalProvider));
			this.setModalProvider(modalProvider);
			if (this.signer) {
				this.mintContract = new ethers.Contract(MINT_ADDRESS, MINT_ABI, this.signer);
			}
			await this.getData();

			this.modalProvider.on('accountsChanged', async (accounts: string[]) => {
				await this.setProvider(new Web3Provider(modalProvider));
				if (this.signer) {
                    this.mintContract = new ethers.Contract(MINT_ADDRESS, MINT_ABI, this.signer);
				}
				await this.getData();
			});
		} catch (e) {
			console.error('error:', e);
		}
	};

    getData = async () => {
        if (!this.provider) return;
        try {
            await Promise.all([
				this.setMintedAmount(),
				this.getCurrentBlock(),
				this.setSaleStatus(),
				this.setWhitelisted(),
			]);
        } catch (e) {
            console.log('error refreshing contract data:', e);
			return;
        }
    }

    setMintedAmount = action(async () => {
		console.log('setMintedAmountCheck:', this.store.wallet.provider, this.mintContract);
		if (!this.store.wallet.provider) return;
		this.mintedAmount = await this.mintContract?.totalCount();
	});

	setSaleStatus = action(async () => {
		if (!this.store.wallet.provider) return;
		this.presalePaused = await this.mintContract?.presalePaused();
		this.salePaused = await this.mintContract?.salePaused();
	});

	setWhitelisted = action(async () => {
		if (!this.store.wallet.provider) return;
		this.isWhitelisted = await this.mintContract?.whitelistEligible(this.store.wallet.address);
	});

	getCurrentBlock = action(async () => {
		if (!this.store.wallet.provider) return;
		this.currentBlock = await this.store.wallet.provider.getBlockNumber();
	});

	disconnect = action(async () => {
		this.web3Modal.clearCachedProvider();
		this.signer = null;
		this.provider = null;
		this.address = null;
	});

    buy = action(async (amount: number): Promise<number | string> => {
		try{
			if (!this.provider) {
				return 'Provider not initialized.';
			}
			const signer = this.provider.getSigner();
			const userAddress = await signer.getAddress();
			const mintingContract = new ethers.Contract(MINT_ADDRESS, MINT_ABI, signer);
			
			const mintCost = ethers.utils.formatUnits(MINT_PRICE);
			const totalCost = new BigNumber(amount).multipliedBy(mintCost).multipliedBy(1e18);
			const overrides = {
				value: totalCost.toString(),
			};
			console.log('mint info:', signer, mintCost, totalCost.toString(), overrides, mintingContract);

			const buyReturn = await mintingContract.buy(userAddress, amount, overrides);
			console.log('waiting on buy return:', buyReturn);
			await buyReturn.wait();
			await this.setMintedAmount();
			return amount;
		} catch (e: any) {
			console.error('Error:', e)
			return e.message;
		}
	});
}
