import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export const ConnectButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	borderRadius: '80px',
	fontSize: '14px',
	color: '#FFFFFF',
	borderColor: '#FFFFFF',
	borderWidth: '4px',
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	filter: 'drop-shadow(0px 4px 4px #21458E) drop-shadow(3px 5px 10px rgba(33, 69, 142, 0.25))',
}));

export const StandardButton = styled(Button)(({ theme }) => ({
	'&.Mui-disabled': {
		pointerEvents: 'auto',
		borderColor: 'rgb(33, 69, 142, 0.4)',
		borderWidth: '4px',

		'&:hover': {
			opacity: 0.95,
			backgroundColor: 'white',
		},
	},
	backgroundColor: 'white',
	color: theme.palette.text.primary,
	borderColor: theme.palette.text.primary,
	'&:hover': {
		opacity: 0.95,
		backgroundColor: 'white',
	},
	textTransform: 'none',
	borderRadius: '80px',
	fontSize: '14px',
	borderWidth: '4px',
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
}));
