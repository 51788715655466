import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { RAINBOW_GRADIENT } from '../../config/constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(8),
			marginLeft: theme.spacing(4),
			textAlign: 'center',
			height: '500px',
			color: theme.palette.text.primary,
			backgroundImage: 'url(assets/images/roll-paper.png)',
			backgroundSize: '100% 100%',
			width: '450px',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(8),
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '300px',
			},
		},
		gitcoin: {
			height: '38px',
			[theme.breakpoints.down('md')]: {
				height: '24px',
			},
		},
		ripMedical: {
			width: '101px',
			[theme.breakpoints.down('md')]: {
				width: '65px',
			},
		},
		largeImage: {
			width: '101px',
			[theme.breakpoints.down('md')]: {
				height: '68px',
			},
		},
		rainbowText: {
			backgroundImage: RAINBOW_GRADIENT,
			WebkitBackgroundClip: 'text',
			color: 'transparent',
		},
		dateLabels: {
			marginTop: '-32px',
		},
		labelItem: {
			marginTop: 0,
			padding: theme.spacing(0, 0, 0, 4.5),
		},
	}),
);

export const TPPaper = observer(() => {
	const classes = useStyles();

	return (
		<Grid className={classes.mainContainer} container direction="column" justifyContent="space-between">
			<Typography variant="h5">FRESH ROLLS!</Typography>
			<Typography variant="h3" className={classes.rainbowText}>
				MINT IS OPEN!
			</Typography>

			<Typography>Proudly Supporting:</Typography>
			<Grid container direction="row" justifyContent="space-evenly" alignItems="center">
				<Grid item xs={4}>
					<img alt="gitcoin" className={classes.gitcoin} src="/assets/icons/gitcoin.svg" />
				</Grid>
				<Grid item xs={4}>
					<img alt="giveth" className={classes.gitcoin} src="/assets/icons/giveth-full-logo.svg" />
				</Grid>
				<Grid item xs={4}>
					<img alt="RIP Medical Debt" className={classes.ripMedical} src="assets/icons/RIPMedicalDebt_01.svg" />
				</Grid>
			</Grid>
			<Typography className={classes.rainbowText}>It feels good to give a sheet!</Typography>
		</Grid>
	);
});

export default TPPaper;
