import { action, extendObservable } from 'mobx';
import { COLLAPSE_WIDTH } from '../config/constants';
import { RootStore } from './RootStore';

export default class UiStore {
	private readonly store!: RootStore;

	public desktopView!: boolean;

	constructor(store: RootStore) {
		this.store = store;
		this.desktopView = !!window && window.innerWidth >= COLLAPSE_WIDTH;
		extendObservable(this, {
			desktopView: this.desktopView,
		});

		// Check if sidebar should be open or not
		window.onresize = () => {
			this.checkWindowSize();
		};
	}

	checkWindowSize = action((): void => {
		this.desktopView = window.innerWidth >= COLLAPSE_WIDTH;
	});
}
