import React, { useContext } from 'react';

import { StoreContext } from '../../mobx/RootStore';
import { observer } from 'mobx-react-lite';
import { shortenAddress } from '../../helpers/eth';
import { ConnectButton } from '../common/buttons';
import { createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { RAINBOW_GRADIENT } from '../../config/constants';

export const navHeight = 4;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rainbowBackground: {
			background: RAINBOW_GRADIENT,
			[theme.breakpoints.down('md')]: {
				fontSize: '.7rem',
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
		blockNumber: {
			paddingTop: theme.spacing(0.5),
			fontSize: '.6rem',
			color: 'rgba(57, 57, 57, 0.87)',
			[theme.breakpoints.down('md')]: {
				fontSize: '.5rem',
			},
		},
	}),
);

export const WalletWidget = observer(() => {
	const store = useContext(StoreContext);
	const classes = useStyles();

	const {
		wallet: { address, currentBlock, connect, disconnect },
	} = store;
	const userConnected = !!address;

	const handleConnect = async () => {
		await connect();
	};

	const handleDisconnect = async () => {
		await disconnect();
	};

	return (
		<>
			<Grid container direction="column" alignItems="flex-end">
				<ConnectButton
					className={classes.rainbowBackground}
					variant="outlined"
					onClick={async () => (userConnected ? await handleDisconnect() : await handleConnect())}
				>
					{userConnected ? shortenAddress(address).toUpperCase() : 'CONNECT WALLET'}
				</ConnectButton>
				{userConnected ? (
					<Typography className={classes.blockNumber}>Current Block Number: {currentBlock}</Typography>
				) : null}
			</Grid>
		</>
	);
});

export default WalletWidget;
